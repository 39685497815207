<template>
    <main>
        <card></card>
        <grafica></grafica>
         
    </main>
</template>
<script>
import card from "./componentHome/card";
import grafica from "./componentHome/grafica";
export default {
    components:{card,grafica},
}
</script>

